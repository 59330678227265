import { Controller } from "stimulus"
import Rails from "@rails/ujs";

/*
 *
 * Usage:
 * <form data-controller="ajax-request" data-ajax-request-url="/sample-path">
 *   <button data-action="click->ajax-request#makeRequest">Make Request</button>
 * </form>
 *
 */
export default class extends Controller {

  /*
   * https://github.com/rails/rails/blob/cb3b37b37975ceb1d38bec9f02305ff5c14ba8e9/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L15
   * UJS has a nice Rails.ajax method, which worked in older version of IE & is a lot like jQuery's $.ajax
   */
  makeRequest(event) {
		const value = event.target.dataset.value
		console.log(event.target.dataset.value)

   //Rails.ajax({
   //  type: "PUT",
   //  url: this.data.get('url'),
   //  data: {}
   //  //data: new FormData(this.element)
   //})
  }
}
