import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
  }

  initialize() {

    const primarycolor = '#1e3d73'
		var task

    $('.scrollertodo').slimScroll({
      height: '500px',
      color: '#fff'
    })

		// Menu
    $('.todo-menu a').on('click', function () {
      $('.todo-menu a').removeClass('active');
      $(this).addClass('active');
      $('.todo-item').hide();
      $('.' + $(this).data("todotype")).show(500);
      return false;
    });

		// Checkbox
    $('.todo-item .chkbox input[type="checkbox"]').on('click', function () {
			console.log('moo')
      if ($(this).prop("checked") == true) {
        $(this).parent().parent().addClass('completed');
				// Make API call to mark as completed
      } else {
        $(this).parent().parent().removeClass('completed');
				// Make API call to mark as not completed
      }
    });

		// Add data to layout
    $(".add-todo-form").submit(function (event) {
      var title = $('#title').val();
      var description = $('#description').val();
      var taskclass = '';

      if ($('#starred').prop("checked") == true) {
        taskclass = 'starred';
      }
      if ($('#important').prop("checked") == true) {
        taskclass = taskclass + ' important';
      }
      var today = moment().format('D MMM, YYYY');
      var html = `<li class="todo-item ` + taskclass + `">
                <label class="chkbox">
                <input type="checkbox" checked="checked">
                <span class="checkmark mt-2"></span>
                </label>
                <div class="todo-content">
                <h3>` + title + `</h3>
                <p class="text-muted mb-0 font-weight-bold todo-date">` + today + `</p>
                <p class="small-content text-muted mb-0">` + description + `</p>
                </div>
                <div class="ml-auto">
                <i class="icon-star mr-2 font-15"></i>
                <i class="icon-exclamation font-15 mr-2"></i>
                </div>
                <div>
                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="icon-options-vertical font-15"></i></a>
                <div class="dropdown-menu p-0 m-0 dropdown-menu-right">
                <a class="dropdown-item edit-todo" href="#">Edit</a>
                <a class="dropdown-item delete" href="#">Delete</a>
                </div>
                </div>
                </li>`;

      $(".todo-list").prepend(html);
      $('#newtodo').modal('toggle');
      event.preventDefault();
    });

		// Delete data
    $(".todo-list").on("click", ".delete", function () {
      $(this).closest('.todo-item').addClass('bg-danger');
      $(this).closest('.todo-item').slideUp(550, function () {
        $(this).closest('.todo-item').remove();
      });
    });

		// edit
    $(".todo-list").on("click", ".edit-todo", function () {
      task = $(this).closest('.todo-item');
			console.log(task)
      $('#edit-title').val($(this).closest('.todo-item').find('h3').html());
      $('#edit-description').val($(this).closest('.todo-item').find('.small-content').html());
      $('#edit-date').val($(this).closest('.todo-item').find('.todo-date').html());
      if ($(this).closest('.todo-item').hasClass("starred")) {
        $('#edit-starred').prop("checked", true);
      }
      if ($(this).closest('.todo-item').hasClass("important")) {
        $('#edit-important').prop("checked", true);
      }
      $("#edittodo").modal('');
    });

		// edit form
    $(".edit-todo-form").submit(function (event) {
      var title = $('#edit-title').val();
      var description = $('#edit-description').val();
      var taskclass = '';

      if ($('#edit-starred').prop("checked") == true) {
        taskclass = 'starred';
      }
      if ($('#edit-important').prop("checked") == true) {
        taskclass = taskclass + ' important';
      }
      var today = $('#edit-date').val();
      task.addClass(taskclass);
      var html = `
                <label class="chkbox">
                <input type="checkbox" checked="checked">
                <span class="checkmark mt-2"></span>
                </label>
                <div class="todo-content">
                <h3>` + title + `</h3>
                <p class="text-muted mb-0 font-weight-bold todo-date">` + today + `</p>
                <p class="small-content text-muted mb-0">` + description + `</p>
                </div>
                <div class="ml-auto">
                <i class="icon-star mr-2 font-15"></i>
                <i class="icon-exclamation font-15 mr-2"></i>
                </div>
                <div>
                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="icon-options-vertical font-15"></i></a>
                <div class="dropdown-menu p-0 m-0 dropdown-menu-right">
                <a class="dropdown-item edit-todo" href="#">Edit</a>
                <a class="dropdown-item delete" href="#">Delete</a>
                </div>
                </div>
                `;

      task.html(html);
      $('#edittodo').modal('toggle');
      event.preventDefault();
    });

		// Search
    $(".todo-search").on("keyup", function () {
      var v = $(".todo-search").val().toLowerCase();
      var rows = $('.' + $('.todo-menu li a.active').data("todotype"));

      for (var i = 0; i < rows.length; i++) {
        var fullname = rows[i].getElementsByClassName("todo-content");
        fullname = fullname[0].innerHTML.toLowerCase();
        if (fullname) {
          if (v.length == 0 || (v.length < 1 && fullname.indexOf(v) == 0) || (v.length >= 1 && fullname.indexOf(v) > -1)) {
            rows[i].style.animation = 'fadein 7s';
            rows[i].style.display = "flex";
          } else {
            rows[i].style.display = "none";
            rows[i].style.animation = 'fadeout 7s';
          }
        }
      }
    });
  }

}
