import { Controller } from 'stimulus'
import '../src/datatable/css/dataTables.bootstrap4.css'
import '../src/datatable/js/jquery.dataTables'
import '../src/datatable/js/dataTables.bootstrap4'

export default class extends Controller {

  connect() {
  }

  initialize() {
    $('#airports').DataTable({
      pagingType: 'full_numbers',
      processing: true,
      serverSide: true,
      ajax: {
        url: $('#airports').data("source"),
        type: 'GET'
      },
      lengthMenu: [
        10,
        25,
        50
      ],
      columns: [
        { data: 'country_name' },
        { data: 'state_name' },
        { data: 'city' },
        { data: 'name' },
        { data: 'icao' },
        { data: 'iata' },
        { data: 'elevation' },
        { data: 'lat' },
        { data: 'lon' },
        { data: 'tz' },
        {
          class: 'details-control',
          orderable: false,
          data: 'details',
          defaultContent: ''
        },
      ]
    })
  }
}
